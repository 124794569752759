
.data{
    padding: 0 25px;
    height: 100%;
    /*overflow: auto;*/
    .data-header{
        .data-title{
            font-size: 14px;
            font-weight: 400;
        }
        .data-content{
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            line-height: 35px;
            .data-item{
                display: flex;
                flex-direction: column;
                .g-num{
                    font-size: 24px;
                    font-weight: bold;
                    color: #5F55F1;
                }
                .added-this-week{
                    & i{
                        font-size: 14px;
                        color: #FFB624;
                        margin: 0 14px;
                    }
                    & .num{
                        font-size: 14px;
                        font-weight: bold;
                        color: #FFB624;
                    }

                }
            }
            .text{
                color:#666666;
                font-size: 14px;
            }

        }
    }

    .data-trend{
        margin-top: 20px;

        ::v-deep.tooltip {
            height: 59px;
            border-radius: 2px;
            box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);
            background: #fff;

            .tooltip-box {
                padding: 13px 20px 10px;
            }

            .tooltip-title {
                font-size: 12px;
                color: #999999;
            }

            .tooltip-title-right {
                float: right;
                padding-right: 10px;
                font-size: 16px;

                &.purple {
                    color: #5F55F1;
                }

                &.blue {
                    color: #04A8FF;
                }
            }

            .tooltip-title-left {
                color: #333;
            }
        }
    }

    .data-menu{
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        .item {
            line-height: 1;
            display: inline-block;
            padding: 10px 12px;
            cursor: pointer;
            background: #F1F0FE;
            border-radius: 5px 0 0 5px;
            &:hover{
                background: #564CED;
                color: #FFFFFF;
            }
            &:last-of-type {
                border-radius: 0 5px 5px 0;
            }
        }

        .selected{
            background: #564CED;
            color: #FFFFFF;
        }
    }
}

