
.fan-analysis {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 20px;
    .analysis-left {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 100%;
        border-right: 1px solid #EAEAEA;
        padding: 0 20px;
        .gender-distribution {
            display: flex;
            flex-direction: column;
            margin-bottom: 50px;
            .title {
                font-size: 14px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
            }
            .distribution-chart {
                .chart-icon {
                    display: flex;
                    justify-content: space-between;
                    color: #34aa7f;
                    margin-top: 10px;
                    & i {
                        font-size: 24px;
                    }
                }
                .chart-content {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    height: 20px;
                    margin-top: 10px;
                    .man {
                        background-color: #34aa7f;
                    }
                    .woman {
                        flex: 1;
                        background-color: #46dbab;
                    }
                }
                .legend {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: rgba(102, 102, 102, 1);
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;

                    .legend-man {
                        display: flex;
                        flex-direction: column;
                    }
                    .legend-woman {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }
        .age-distribution {
            .title {
                font-size: 14px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
            }
        }
    }
    .analysis-right {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 100%;
        padding: 0 20px;
        .title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            padding-right: 5px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
        }
        .content {
            flex: 1;
            overflow: auto;
            padding-right: 5px;
        }
        .area-distribution {
            flex: 1;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            height: calc(100% - 66px);
            .area-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
                .area-name {
                    width: 50px;
                    white-space: nowrap;
                    overflow: hidden;
                }
                ::v-deep .el-progress {
                    width: 80%;
                    padding: 0 10px;
                }
                ::v-deep .el-progress-bar__inner {
                    background: linear-gradient(45deg, #ffa72a, #f8b95f 98%);
                }
            }
        }
    }
    .null-data-area{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
    }
}
